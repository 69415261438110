@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');


.default-font {
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-weight: 300;
}

html, body {
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-weight: 300;
  margin: 0px !important; 
  height: 100%;
  font-size: 16px;
}

.pricing-card-features{
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  /* text-shadow: 1px 1px rgb(228, 228, 228); */
  font-size: calc(10px + 0.5vmin);
}
