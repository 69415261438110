@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');


.default-font {
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-weight: 300;
}

.assetmanager-header {
  font-size: calc(10px + 2vmin);
}

.assetmanager-subheader{
  font-size: calc(20px + 1vmin);
}

.assetmanager-subtext{
  font-size: calc(12px + 1vmin);
}

.why-us-container{
  max-width: 3840px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Asset-manager-header{
    background-color: #ffffff;
    min-height: 94vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: rgb(32, 32, 32);
  }

  .Asset-manager-body{
    background-color: #ffffff;
    min-height: 35vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: rgb(32, 32, 32);
  }
  

  html, body {
    margin: 10;
    height: 100%;
  }

  .asset-manager-login-error{
    background-color:   #ffffff;
    min-height:           80vh;
    display:              flex;
    flex-direction:       column;
    align-items:          center;
    justify-content:      center;
    /* font-size:            calc(25px + 1vmin); */
    color:              rgb(32, 32, 32);
  }

  .asset-manager-empty-bucket{
    background-color:   #ffffff;
    min-height:           94vh;
    display:              flex;
    flex-direction:       column;
    align-items:          center;
    justify-content:      center;
    font-size:            calc(25px + 1vmin);
    color:              rgb(32, 32, 32);
  }

  
  .signout{
    background-color: #ffffff;
    min-height: 5vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: rgb(32, 32, 32);
  }

  .sign-out-btn{
    position: absolute;
    right:    3%;
    top:      10%;
  }

  .asset-refresh-btn{
    font-size: 25px;
  }

  .card-padding {
    padding-top: 50px;
    padding-right: 30px;
    padding-bottom: 50px;
    padding-left: 30px;
  }
  

  .card-margin {
    margin-top: 30px !important;
    margin-left: 30px !important;
    margin-bottom: 30px !important;
  }

  .mt-0 {
    margin-top: 5px !important;
  }
  
  .ml-1 {
    margin-left: 5px !important;
  }
  
  .px-2 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  
  .p-3 {
    padding: 5px !important;
  }


  .checkbox-text{
    font-size: calc(6px + 1vmin);
  }

  .vl {
    border-left: 6px solid green;
    height: 500px;
  }


  /*************************************/

.buttonZoomAssetManager {
  transition: transform .2s;

  /* -webkit-filter: drop-shadow(1px 1px 1px grey); 
  filter: drop-shadow(1px 1px 1px grey); */
}

.buttonZoomAssetManager:hover {
  /* -ms-transform: scale(1.05);    // IE 9
  -webkit-transform: scale(1.05);   // Safari 3-8
  transform: scale(1.05); */

  -webkit-filter: drop-shadow(1px 1px 3px grey); 
  filter: drop-shadow(1px 1px 3px grey);
}

