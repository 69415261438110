.upload-logo {
    height:             10vmin;
    pointer-events:     none;
  }

.upload-header {
    background-color:   #ffffff;
    min-height:           94vh;
    display:              flex;
    flex-direction:       column;
    align-items:          center;
    justify-content:      center;
    font-size:            calc(10px + 2vmin);
    color:              rgb(32, 32, 32);
  }

  .upload-title {
    display:              flex;
    flex-direction:       column;
    align-items:          center;
    justify-content:      center;
    color:              #ffffff;
    font-size:            calc(25px + 1vmin);
  }

  .upload-subheader{
    font-size:            calc(20px + 1vmin);
  }

  .upload-subtext{
    font-size:            calc(8px + 1vmin);
  }

  html, body {
    margin:               10; 
    height:               100%;
  }

  .upload-login-error{
    background-color:   #ffffff;
    min-height:           94vh;
    display:              flex;
    flex-direction:       column;
    align-items:          center;
    justify-content:      center;
    font-size:            calc(25px + 1vmin);
    color:              rgb(32, 32, 32);
  }

  .Progress-bar{
    width:                70%;
  }

  .upload-button {    
    max-width:            3840px;
    display:              flex;
    flex-wrap:            wrap;
    justify-content:      center;
    font-size:            calc(25px + 1vmin);
  }

  P1 {
    font-size:            0.6em; /* 14px/16=0.875em */
  }