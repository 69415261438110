@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');


html, body {
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-weight: 300;
  margin: 0px !important; 
  height: 100%;
  font-size: 16px;
}

  .fake-panel{
    position:         absolute;
    top:              85%;
    left:             50%;
    transform:        translate(-50%, -50%);
    width:            auto;
    max-width:        100%;
    
    /* This solved the issue with panel being wrapped into second line. Source: https://stackoverflow.com/questions/30086913/css-positioned-absolute-element-automatic-width-outside-of-parents-bounds */
    white-space:      nowrap;
    border-radius:    1rem;
    padding-left:     1.8rem;
    padding-right:    1.8rem;
    background-color: rgba(214, 214, 214, 0.4);
  }

  .panelButton{
    width:              auto;
    height:             auto;
    max-width:          1vw;
    max-height:         1vw;
    background-color:   rgba(255,255,255, 0.3);
    margin:             0.5rem;
    border-radius:      0.75rem;
    transition:         background-color 0.2s ease-in-out, transform 0.2s;
    box-shadow:         1px 1px 8px grey;
  }

  #ar-viewer-button-load {
    background-repeat: no-repeat;
    background-size: 12px 12px;
    background-position: 6% 50%;
    background-color: #000;
    color: white;
    cursor: pointer;
    border-radius: 6px;
    display: inline-block;
    padding: 10px 18px 9px 40px;
    font-weight: 500;
    box-shadow: 0 0 8px rgba(0,0,0,.2), 0 0 4px rgba(0,0,0,.25);
    position: absolute;
    left: 90%;
    top: 90%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 100;
  }


/* The Modal (background) */
.modal {
  position: absolute;
  top: 40px;
  bottom: 40px;
  background-color: gray;
  width: 100%;
}

/* Modal Content (image) */
.modal-content {
  margin: auto;
  display: block;
  width:  100%;
  max-width: 100%;
  padding: 10px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}


.modal-footer {
  margin: auto;
  display: block;
}

.modal-card-padding{
  padding: auto;
}

.modal-card-margin {
  margin-left: auto;
  margin-right: auto;
}

.Modal {
    position: absolute;
    top: 40px;
    bottom: 40px;
    background-color:gray;
    width: 100%;
  }
  
  .btn-margin {
    margin-top: 5px !important;
    margin-left: 5px !important;
    margin-bottom: 5px !important;
  }

  /**
   Toast
   */
  .toast-header {
    font-family:  'Open Sans', sans-serif, Arial, Helvetica;
    font-weight:  400;
    font-size:    1.2rem;
  }

  .toast-body {
    font-family:  'Open Sans', sans-serif, Arial, Helvetica;
    font-weight:  400;
    font-size:    1rem;
    text-align:   justify;
  }
  

  /**
    Asset Editor
  */
  .asset-editor-footer{
    font-family:  'Open Sans', sans-serif, Arial, Helvetica;
    font-weight:  400;
    font-size:    1rem;
  }


  /**
    Links section
  */
  .links-header{
    font-family:  'Open Sans', sans-serif, Arial, Helvetica;
    font-weight:  400;
    font-size:    1rem;
  }

  .links-body{
    font-family:  'Open Sans', sans-serif, Arial, Helvetica;
    font-weight:  300;
    font-size:    1rem;
  }
