@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');


/* Source: https://www.youtube.com/watch?v=f3mwKLXpOLk&t=471s */
:root{
  --gradient: linear-gradient(
    45deg,
    /* #845ec2, */
    /* #d65db1, */
    /* #ffb671, */
    #f5b981,
    #fcbdc2,
    #f5b981
    /* #fcac6b */
    /* #f9f871 */
  );
}


html, body {
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-weight: 300;
  margin: 0px !important; 
  height: 100%;
  font-size: 16px;
}

/*****************************/

.Model-Showcase{
  /* background-image: linear-gradient(to bottom, #FFAB30, #FF9800, #FFAB30); */
  /* background-image: linear-gradient(to bottom, #e28800, #FF9800, #FFAB30); */

  background-image: var(--gradient);
  background-size: 200%;
  animation: Model-Showcase-Bg-Animation 6s infinite alternate;

  /* background-repeat: no-repeat, no-repeat;
  background-image: linear-gradient(to bottom, #FFAB30, #FF9800, #FFAB30), url('../images/expectrum-1191724.png');
  background-blend-mode: lighten;
  background-position: left;
  background-size: cover; */
}

@keyframes Model-Showcase-Bg-Animation {
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}


.leftArrowButtonZoom {
  /* padding: 50px; */
  /* background-color: green; */
  transition: transform .2s;
  /* width: 200px;
  height: 200px; */
  /* margin: 0 auto; */
}

.leftArrowButtonZoom:hover {
  -ms-transform: scale(1.2) translateX(-20px); /* IE 9 */
  -webkit-transform: scale(1.2) translateX(-20px); /* Safari 3-8 */
  transform: scalex(1.2) translateX(-20px); 
}

.rightArrowButtonZoom {
  /* padding: 50px; */
  /* background-color: green; */
  transition: transform .2s;
  /* width: 200px;
  height: 200px; */
  /* margin: 0 auto; */
}

.rightArrowButtonZoom:hover {
  -ms-transform: scale(1.2) translateX(20px); /* IE 9 */
  -webkit-transform: scale(1.2) translateX(20px); /* Safari 3-8 */
  transform: scalex(1.2) translateX(20px); 
}

.view3dButtonZoom {
  /* padding: 50px; */
  /* background-color: green; */
  transition: transform .2s;
  /* width: 200px;
  height: 200px; */
}

.view3dButtonZoom:hover {
  -ms-transform: scale(1.05); /* IE 9 */
  -webkit-transform: scale(1.05); /* Safari 3-8 */
  transform: scale(1.05); 
}



/*****************************/

/* FAQ's Section */
.Faqs-Showcase{
  background-image: linear-gradient(to bottom, #CDCECF, #F5F5F6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.faqs-question{
  /*display: flex;
  flex-direction: column;*/
  /* text-shadow: 1px 1px 3px rgb(202, 202, 202); */
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  color: rgb(88, 88, 88);
  font-size: calc(15px + 0.75vmin);
  /* font-size: '1rem'; */
  font-weight: 400;
}

.faqs-answer{
  /*display: flex;
  flex-direction: column;*/
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  color: rgb(60, 60, 60);
  font-size: calc(10px + 0.75vmin);
  font-weight: 400;
}


.Supported-Browsers{
  background-image: linear-gradient(to bottom right, #A5B2C2, #F5F5F6);
}

.Quote-Section{
  background-image: linear-gradient(to bottom, #97A9C0, #F5F5F6);
}

/*****************************/

/* Flexbox Styles, Section: Why Us*/
.why-us-container{
  /*max-width: 3840px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;*/
}

.why-us-item {
  box-sizing: border-box;
  min-width: 300px;
  max-width: 800px;
  flex: 1;
  margin-top: 20px;
  margin-bottom: 20px;
}

.why-us-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(15px + 2vmin);
}

.why-us-subheader{
  /*display: flex;
  flex-direction: column;*/
  font-size: calc(16px + 1vmin);
  font-weight: 400;
}

.why-us-subtext{
  /*display: flex;
  flex-direction: column;*/
  font-size: calc(11px + 1vmin);
}

/*****************************/

/* Flexbox Styles, Section: Supported File Formats*/
.supported-file-format-container{
  max-width: 3840px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.supported-file-format-item {
  box-sizing: border-box;
  min-width: 200px;
  max-width: 800px;
  flex: 1;
  margin-top: 20px;
  margin-bottom: 20px;
}
/*****************************/

.why-3d-viewer-header {
  max-width: '80%';
  font-size: calc(15px + 2vmin);
}


/*****************************/
.home-header {
  /*display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;*/
  /* font-family: Arial, Helvetica, sans-serif; */
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: calc(17px + 2vmin);

}

.home-subheader{
  /*display: flex;
  flex-direction: column;*/
  /* text-shadow: 1px 1px 3px rgb(202, 202, 202); */
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: calc(13px + 1vmin);
}

.home-subtext{
  /*display: flex;
  flex-direction: column;*/
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: calc(13px + 0.75vmin);
}

.home-sub-subtext{
  /*display: flex;
  flex-direction: column;*/
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: calc(10px + 0.75vmin);
}

/*****************************/

.foot-note{
  /*display: flex;
  flex-direction: column;*/
  font-size: calc(10px + 0.75vmin);
}

.header {
  background-color: white;
}

.demo-assets {
  background-color: #8CCCFF;
}




.backup {
  background-color: #ffffff;
  min-height: 94vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(32, 32, 32);
}

.btn-margin {
  margin-top: 5px !important;
  margin-left: 5px !important;
  margin-bottom: 5px !important;  
}

.card-margin {
  margin-top: 30px !important;
  margin-left: 15px !important;
  margin-right: 15px !important;
  margin-bottom: 30px !important;
}

.mt-0 {
  margin-top: 5px !important;
}

.ml-1 {
  margin-left: 5px !important;
}

.px-2 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.p-3 {
  padding: 5px !important;
}

.pricing-card-header{
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: calc(22px + 1vmin);
}

.pricing-card-price{
  color: #FF5722;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: calc(15px + 1vmin);
}

.pricing-card-features{
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  /* text-shadow: 1px 1px rgb(228, 228, 228); */
  font-size: calc(12px + 0.5vmin);
}

.pricing-card-goodfor{
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  text-align: center;
  font-size: calc(5px + 1vmin);
}


/*****************************/

.buttonZoom {
  /* padding: 50px; */
  /* background-color: green; */
  transition: transform .2s;
  /* width: 200px;
  height: 200px; */
  margin: 0 auto;

  -webkit-filter: drop-shadow(1px 1px 1px rgb(223, 179, 179)); 
  filter: drop-shadow(1px 1px 1px rgb(223, 179, 179));
}

.buttonZoom:hover {
  -ms-transform:      scale(1.2); /* IE 9 */
  -webkit-transform:  scale(1.2); /* Safari 3-8 */
  transform:          scale(1.2);

  -webkit-filter:     drop-shadow(2px 2px 3px rgb(223, 179, 179)); 
  filter:             drop-shadow(2px 2px 3px rgb(223, 179, 179));
}

/*****************************/

.IndividualOrCompanyButtonZoom {
  /* padding: 50px; */
  /* background-color: green; */
  transition: transform .2s;
  /* width: 200px;
  height: 200px; */
  margin-top: 20px;
}

.IndividualOrCompanyButtonZoom:hover {
  -ms-transform: scale(1.05); /* IE 9 */
  -webkit-transform: scale(1.05); /* Safari 3-8 */
  transform: scale(1.05); 
}


/*****************************/

.pricingPackageZoom {
  /* padding: 50px; */
  background-color: white;
  transition:  background-color 0.5s ease-in-out, transform 0.5s, box-shadow 0.5s;
  box-shadow: 3px 3px 15px grey;
  /* width: 200px;
  height: 200px; */
  margin: 0 auto;
}

.pricingPackageZoom:hover {
  -ms-transform: scale(1.05); /* IE 9 */
  -webkit-transform: scale(1.05); /* Safari 3-8 */
  transform: scale(1.05);
  background: rgb(251, 254, 255);
  box-shadow: 8px 8px 20px rgb(125, 167, 179)
}


/* Footer Section */
.subscription-maintext{
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: calc(0.3rem + 1.5vmin);;
  color: #DCE775;
  margin-top: 10px;
}

.subscription-subtext{
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  text-align: justify;
  font-size: calc(5px + 1vmin);
  color: #D9E3F0;
}


/*****************************/
.quote-header {
  /*display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;*/
  /* font-family: Arial, Helvetica, sans-serif; */
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: calc(15px + 2vmin);

}

.quote-subheader{
  /*display: flex;
  flex-direction: column;*/
  /* text-shadow: 1px 1px 3px rgb(202, 202, 202); */
  font-family: 'Open Sans', sans-serif, Arial, Helvetica;
  font-size: calc(13px + 1vmin);
}


/* .browser-icons{
  -webkit-box-reflect: below 0px linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.4));
} */